<template>
  <div>
    <div class="title">俱乐部章程</div>
    <div v-if="associationData" v-loading="loading">
      <div class="content" v-html="associationData.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "俱乐部章程",
      meta: [
        {
          name: "keyWords",
          content: "上海市国际货运代理行业协会章程,上海货代协会章程",
        },
        {
          name: "description",
          content:
            "上 海 市 国 际 货 运 代 理 行 业 协 会 （ shanghai international freight forwarders association）是在我国改革开放不断深化，国际货运代 理业快速发展条件下，于 1992 年 9 月成立，是我国（除港澳台地区外）最早成立的省 市级国际货运代理行业协会。",
        },
      ],
    };
  },
  data() {
    return {
      loading: false,
      associationData: null,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      let params = {};
      params.nopage = 1;
      params.source = this.PJSource;
      params.language = this.LOCALE === "en" ? 1 : 2;
      params.content_type = 71;
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      if (res.success) {
        this.loading = false;
        this.associationData = res.data[0];
      }
    },
  },
};
</script>

<style scoped lang="less">
.title {
  font-size: 24px;
  font-weight: bold;
  color: #666666;
  margin-top: 13px;
  margin-bottom: 24px;
}

.content {
  padding: 30px 30px 58px;
  background: #ffffff;
  div {
    font-weight: 400;
    color: #666666;
    font-size: 14px;
    line-height: 25px;
  }
  .liltTitle {
    text-align: center;
    font-size: 18px;
    margin: 20px 0;
  }

  .marginT {
    margin-top: 20px;
  }
}
</style>
